//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "./networking/api";
import { Dialog,Toast } from "vant";
export default {
  name: "Detail",
  data() {
    return {
      actList: [],
      loading:false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const that = this;
      this.loading = true
      this.axios
        .get(api.getIncentiveRedPacketRecordList, {
          params: {
            actionId: that.$route.query.actionId,
            storeId: that.$route.query.storeId,
          },
        })
        .then((res) => {
          console.log("getIncentiveRedPacketRecordList", res.data);
          this.actList = res.data.data;
          this.loading = false
        });
    },
    receiveGift(item) {
      const that = this;
      if (that.loading) {
        this.$toast({ message: "请勿重复点击", duration: 2000 });
        return;
      }
      Dialog.confirm({
        title: "确认领取？",
      })
        .then(() => {
          that.loading = true;
          Toast.loading({
            message: "正在领取中...",
            duration: 0,
            mask:true
          });
          this.axios
            .get(api.receiveIncentiveRedPacket, {
              params: {
                storeId: item.storeId,
                id: item.id,
              },
            })
            .then((res) => {
              Toast.clear();
              that.loading = false;
              if (res.data && res.data.code == 0) {
                this.$toast({ message: "领取成功", duration: 2000 });
                this.getList();
              } else {
                this.$dialog.alert({
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          that.loading = false;
        });
    },
  },
};
